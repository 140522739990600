<template>
<div v-if="schools.length > 0 && !loading">
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-12">
                <div class="kt-portlet kt-portlet--mobile kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3
                                v-if="isNewSchool"
                                class="kt-portlet__head-title"
                            >
                                Add New School
                            </h3>
                            <h3
                                v-else
                                class="kt-portlet__head-title"
                            >
                                {{ school.externalSchoolId }} {{ school.schoolName }}
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <div class="kt-portlet__head-group">
                                <button
                                    v-if="!isNewSchool"
                                    type="button"
                                    class="btn btn-label-primary btn-bold alignment pull-right"
                                    :disabled="!isFormValid"
                                    @click.stop.prevent="impersonate()"
                                >
                                    <i class="fa fa-user-secret" />
                                    Active Term
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="kt-portlet__body">
                        <SchoolTerms v-if="!isNewSchool" />

                        <div
                            v-if="!isNewSchool"
                            class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"
                        />

                        <div class="kt-section mb-0 mt-3">
                            <div class="kt-section__title">
                                School Details
                            </div>
                        </div>
                        <div class="form-group validated row pl-2 pr-2">
                            <div class="p-3 pr-4 col-xl-4 col-md-6">
                                District:
                                <select
                                    v-model="school.district.districtId"
                                    class="form-control"
                                    :disabled="!isNewSchool"
                                >
                                    <option
                                        v-for="d in districts"
                                        :key="`district_${d.districtId}`"
                                        :value="d.districtId"
                                    >
                                        {{ d.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="p-3 pr-4 col-xl-4 col-md-6">
                                External School Id:
                                <input
                                    v-model="school.externalSchoolId"
                                    :disabled="!isNewSchool"
                                    type="text"
                                    class="form-control"
                                    :class="isExternalSchoolIdValid ? '' : 'is-invalid'"
                                >
                                <div
                                    v-if="!isExternalSchoolIdValid"
                                    class="invalid-feedback"
                                >
                                    Please enter a six character external school id
                                </div>
                            </div>
                            <div class="p-3 pr-4 col-xl-4 col-md-6">
                                School Name:
                                <input
                                    v-model="school.schoolName"
                                    type="text"
                                    class="form-control"
                                    :class="isSchoolNameValid ? '' : 'is-invalid'"
                                >
                                <div
                                    v-if="!isSchoolNameValid"
                                    class="invalid-feedback"
                                >
                                    Please enter a school name
                                </div>
                            </div>
                            <div class="p-3 pr-4 col-xl-4 col-md-6">
                                Primary Contact Email Address:

                                <input
                                    v-if="isNewSchool"
                                    v-model="primaryContact.schoolEmail"
                                    :placeholder="mySchoolDomain ? `e.g. barack.obama@${mySchoolDomain}` : ''"
                                    type="email"
                                    :class="isValidSchoolDomain ? '' : 'is-invalid'"
                                    class="form-control"
                                >
                                <input
                                    v-else
                                    v-model="school.contactEmail"
                                    :placeholder="mySchoolDomain ? `e.g. barack.obama@${mySchoolDomain}` : ''"
                                    type="email"
                                    :class="isValidSchoolDomain && isEmailFound ? '' : 'is-invalid'"
                                    class="form-control"
                                >
                                <div
                                    v-if="!isValidSchoolDomain"
                                    class="invalid-danger-feedback"
                                >
                                    Please enter a valid email address
                                    <template v-if="mySchoolDomain">
                                        ending in <code>@{{ mySchoolDomain }}</code>
                                    </template>
                                </div>
                            </div>

                            <div class="p-3 pr-4 col-xl-4 col-md-6">
                                Google Integration Type
                                <select
                                    v-model="school.googleSetting"
                                    class="form-control"
                                >
                                    <option
                                        v-for="s in googleSettings"
                                        :key="`googleSettings${s}`"
                                        :value="s"
                                    >
                                        {{ s }}
                                    </option>
                                </select>
                            </div>

                            <div class="p-3 pr-4 col-xl-4 col-md-6 row">
                                <div>
                                    <label class="w-100 text-no-wrap">Require 2FA</label>
                                    <span class="kt-switch kt-switch--sm kt-switch--icon">
                                        <label>
                                            <input
                                                v-model="school.requireTwoFactorAuth"
                                                type="checkbox"
                                            >
                                            <span />
                                        </label>
                                    </span>
                                </div>
                                <div v-if="!isNewSchool" class="row">
                                    <div>
                                        <label class="w-100 text-no-wrap">K-5 School</label>
                                        <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                            <label>
                                                <input
                                                    v-model="hasStarsClassroom"
                                                    type="checkbox"
                                                    :disabled="saving"
                                                >
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                    <div>
                                        <label class="w-100 text-no-wrap">Beta School</label>
                                        <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--warning">
                                            <label>
                                                <input
                                                    v-model="school.isBetaSchool"
                                                    type="checkbox"
                                                >
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <template v-if="isNewSchool">
                                <div class="p-3 pr-4 col-xl-4 col-md-6">
                                    Primary Contact First Name:
                                    <input
                                        v-model="primaryContact.firstName"
                                        type="text"
                                        class="form-control"
                                        :disabled="!isNewSchool"
                                        :class="isPrimaryContactFirstNameValid ? '' : 'is-invalid'"
                                    >
                                    <div
                                        v-if="!isPrimaryContactFirstNameValid"
                                        class="invalid-feedback"
                                    >
                                        Please enter a valid first name.
                                    </div>
                                </div>

                                <div class="p-3 pr-4 col-xl-4 col-md-6">
                                    Primary Contact Last Name:
                                    <input
                                        v-model="primaryContact.lastName"
                                        type="text"
                                        class="form-control"
                                        :disabled="!isNewSchool"
                                        :class="isPrimaryContactLastNameValid ? '' : 'is-invalid'"
                                    >
                                    <div
                                        v-if="!isPrimaryContactLastNameValid"
                                        class="invalid-feedback"
                                    >
                                        Please enter a valid last name.
                                    </div>
                                </div>

                                <div class="p-3 pr-4 col-xl-4 col-md-6">
                                    Initial School Year
                                    <input
                                        v-model="schoolTerm.year"
                                        type="number"
                                        class="form-control"
                                        :class="isSchoolYearValid ? '' : 'is-invalid'"
                                    >
                                    <div
                                        v-if="!isSchoolYearValid"
                                        class="invalid-feedback"
                                    >
                                        Please enter a valid school year
                                    </div>
                                </div>
                                <div class="p-3 pr-4 col-xl-4 col-md-6">
                                    Initial School Term
                                    <input
                                        v-model="schoolTerm.term"
                                        type="number"
                                        class="form-control"
                                        :class="isSchoolTermValid ? '' : 'is-invalid'"
                                    >
                                    <div
                                        v-if="!isSchoolTermValid"
                                        class="invalid-feedback"
                                    >
                                        Please enter a valid school term: {{ ['1', '2', '3', '4', '7'].join(', ') }}
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="p-4 mb-2">
                            <div class="kt-form__actions">
                                <template v-if="!isNewSchool">
                                    <button
                                        v-if="!school.deleted"
                                        type="button"
                                        class="btn btn-label-danger btn-bold "
                                        data-dismiss="modal"
                                        :disabled="!isFormValid"
                                        @click.stop.prevent="deleteSchool()"
                                    >
                                        <i class="fa fa-trash" />
                                        Delete School
                                    </button>
                                    <button
                                        v-else
                                        type="button"
                                        class="btn btn-label-warning btn-bold "
                                        data-dismiss="modal"
                                        @click.stop.prevent="restoreSchool()"
                                    >
                                        <i class="fa fa-save" />
                                        Restore School
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-danger btn-bold ml-4"
                                        data-dismiss="modal"
                                        :disabled="permDeleteDisabled"
                                        @click.stop.prevent="confirmDeletePermanently()"
                                    >
                                        <i class="fa fa-ban" />
                                        {{ permDeleteDisabled ? 'Delete After Peak Hours' : 'Delete Permanently' }}
                                    </button>
                                </template>
                                <button
                                    type="button"
                                    class="btn btn-bold ml-3 pull-right"
                                    :disabled="!isFormValid"
                                    :class="{
                                        'kt-spinner kt-spinner--sm kt-spinner--light': saving,
                                        'btn btn-label-primary alignment pull-right': isNewSchool && !saving,
                                        'btn btn-label-success mr-2': !isNewSchool && !saving,
                                    }"
                                    @click.stop.prevent="isNewSchool ? createSchool() : saveSchool()"
                                >
                                    <i
                                        v-if="!saving"
                                        class="fa fa-save"
                                    />
                                    <template v-if="saving">
                                        {{ deleteConfirm == deleteConfirmText ? "Deleting..." : "Saving..." }}
                                    </template>
                                    <template v-else>
                                        {{ isNewSchool ? "Create School" : "Save Changes" }}
                                    </template>
                                </button>
                            </div>
                        </div>

                        <div
                            v-if="!isNewSchool"
                            class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"
                        />
                    </div>
                </div>
                <SchoolSubscriptions
                    v-if="!isNewSchool"
                    :external-school-id="school ? school.externalSchoolId : null"
                    :school-year="thisYear"
                />
            </div>
        </div>
    </div>
    <b-modal
        ref="perm-delete-modal"
        size="xl"
        centered
        @hide="deleteConfirm = ''"
    >
        <template #modal-header="{ close }">
            <h5 class="modal-title">
                Are you sure?
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                :disabled="saving && deleteConfirm == deleteConfirmText"
                @click="close()"
            />
        </template>
        <template #default>
            <div class="kt-portlet__body p-4">
                <div class="tab-content">
                    <template>
                        <div class="kt-section kt-section--first">
                            <div class="kt-section__body">
                                <div class="form-group row">
                                    <label class="col-lg-3 col-form-label">Confirm School Delete:</label>
                                    <div class="col-lg-6">
                                        <input
                                            v-model="deleteConfirm"
                                            type="text"
                                            class="form-control"
                                        >

                                        <span class="form-text text-muted kt-font-danger">
                                            Please enter the text <code>{{ deleteConfirmText }}</code>
                                            to acknowledge that this delete is permanant and to proceed
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <template #modal-footer="{ close }">
            <div
                v-if="saving"
                class="pr-4 font-weight-normal"
            >
                This may take a few minutes...
            </div>
            <button
                v-else
                type="button"
                class="btn btn-secondary mr-3"
                @click="close()"
            >
                Cancel
            </button>

            <button
                v-if="!isProd"
                class="btn btn-danger btn-bold pull-right"
                :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': saving }"
                :disabled="saving || deleteConfirm != deleteConfirmText"
                @click.stop.prevent="deleteSchoolPermanently"
            >
                <i
                    v-if="!saving"
                    class="fa fa-ban"
                />
                {{ saving ? "Deleting..." : "Delete Permanently" }}
            </button>
        </template>
    </b-modal>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import moment from 'moment';
import * as network from '../../network';
import Types from '../../store/Types';
import SchoolTerms from './SchoolTerms.vue';
import SchoolSubscriptions from './SchoolSubscriptions.vue';

export default {
    name: 'CreateOrEditSchool',
    components: {
        SchoolSubscriptions,
        SchoolTerms,
    },
    data() {
        return {
            isEmailFound: true,
            isNewSchool: false,
            validURL: true,
            districts: [],
            saving: false,
            loading: false,
            deleteConfirm: '',
            deleteConfirmText: 'DELETE FOREVER',
            googleSettings: ['District Managed', 'School Managed', 'Disabled'],
            permDeleteDisabled: false,
            primaryContact: { // isNewSchool
                schoolEmail: null,
                firstName: null,
                lastName: null,
            },
            schoolTerm: { // isNewSchool
                year: moment().format('YYYY'),
                term: '1',
            },
            school: {
                schoolId: null,
                externalSchoolId: null,
                schoolName: null,
                author: null,
                contactEmail: null,
                contactUserId: null,
                googleSetting: 'School Managed',
                deleted: null,
                requireTwoFactorAuth: true,
                district: {
                    name: null,
                    districtId: 1,
                    externalDistrictId: null,
                },
                schoolTerms: [],
                subscriptions: [],

                // currenlty not visible in UI
                schoolStatus: null,
                schoolRenewalStatus: null,
                schoolMessageEnabled: false,
                schoolMessage: null,
                schoolMessageScope: null,
                schoolMessageVariant: null,
                isBetaSchool: false,
            },
            hasStarsClassroom: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            userName: (state) => state.user.mappedUserName || state.user.userName,
            schools: (state) => state.database.schools,
        }),
        district() {
            const { districtId } = this.school.district;
            return this.districts.find((d) => d.districtId == districtId) || null;
        },
        isValidSchoolEmail() {
            let schoolEmail = (this.school.contactEmail || '').toLowerCase();
            if (this.isNewSchool) {
                schoolEmail = (this.primaryContact.schoolEmail || '').toLowerCase();
            }
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(schoolEmail);
        },
        thisSchoolDomain() {
            if (!this.isValidSchoolEmail) return null;
            if (this.isNewSchool) {
                if (!this.primaryContact.schoolEmail) return '';
                return this.primaryContact.schoolEmail.split('@')[1].toLowerCase();
            }
            if (!this.school.contactEmail) return '';
            return this.school.contactEmail.split('@')[1].toLowerCase();
        },
        mySchoolDomain() {
            if (!this.district) return null;
            return this.district.schoolEmail;
        },
        isValidSchoolDomain() {
            if (!this.isValidSchoolEmail) return false;
            if (!this.district) return false;
            return this.district.schoolEmail == this.thisSchoolDomain;
        },
        isSchoolNameValid() {
            return (this.school.schoolName || '').length > 0;
        },
        isExternalSchoolIdValid() {
            return (this.school.externalSchoolId || '').length == 6;
        },
        isPrimaryContactFirstNameValid() {
            return (this.primaryContact.firstName || '').length > 0;
        },
        isPrimaryContactLastNameValid() {
            return (this.primaryContact.lastName || '').length > 0;
        },
        thisYear() {
            if (parseInt(moment().format('MM'), 10) < 7) {
                return moment().add(-1, 'years').format('YYYY');
            }
            return moment().format('YYYY');
        },
        isSchoolYearValid() {
            return (this.schoolTerm.year || '').length == 4;
        },
        isSchoolTermValid() {
            return ['1', '2', '3', '4', '7'].indexOf(this.schoolTerm.term) > -1;
        },
        isValidContactEmail() {
            let { contactEmail } = this.school;
            contactEmail = contactEmail || '';
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(contactEmail.toLowerCase());
        },
        isValidURL() {
            if (!this.school.supportLink) return true;
            const re = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/);
            return re.test(this.school.supportLink);
        },
        isFormValid() {
            if (!this.school.district.districtId) return false;
            if (!this.isValidURL && !this.isNewSchool) return false;
            if (!this.isSchoolNameValid) return false;
            if (!this.isExternalSchoolIdValid) return false;

            if (this.isNewSchool) {
                if (!this.isValidSchoolDomain) return false;
                if (!this.isPrimaryContactFirstNameValid) return false;
                if (!this.isPrimaryContactLastNameValid) return false;
                if (!this.isSchoolYearValid) return false;
                if (!this.isSchoolTermValid) return false;
            } else {
                if (!this.isValidContactEmail) return false;
                if (!this.isValidSchoolDomain) return false;
            }
            return true;
        },
        isProd() {
            return window.location.hostname == 'app.syncgrades.com';
        },
    },
    watch: {
        schools() {
            this.populate();
        },
    },
    mounted() {
        this.getDistricts();
        this.populate();
    },
    methods: {
        exit() {
            this.$router.push({ name: 'SuperSchools' });
        },
        confirmDeletePermanently() {
            if (this.saving) return;
            // if (moment().isBetween(moment('09:00:00', 'HH:mm:ss'), moment('17:00:00', 'HH:mm:ss'))) {
            //     this.permDeleteDisabled = true;
            //     return;
            // }
            this.$refs['perm-delete-modal'].show();
        },
        deleteSchoolPermanently() {
            const v = this;
            if (v.saving) return;
            v.saving = true;

            const { school } = v;
            const params = {
                url: {
                    externalSchoolId: school.externalSchoolId,
                },
                body: {
                    schoolId: school.schoolId,
                },
            };

            network.admin.deleteSchool(params, (err, res) => {
                v.saving = false;
                if (err) v.showError('Error permanently deleting school');
                this.$refs['perm-delete-modal'].hide();
                window.location.href = '/configuration/super/schools';
            });
        },
        impersonate() {
            if (this.isNewSchool) return;
            if (!this.school.contactUserId) return this.showError('Cant impersonate, invalid primary contact');
            const currentTerm = this.school.schoolTerms.find((t) => t.current) || null;
            if (!currentTerm) return this.showError('Cant impersonate, cannot find current term');

            const user = {
                userId: this.school.contactUserId,
                schoolTermId: currentTerm.schoolTermId,
            };
            this.$store.dispatch(Types.actions.IMPERSONATE, user);
        },
        populate() {
            const v = this;
            const { externalSchoolId } = v.$route.params;
            const school = v.schools.find((u) => u.externalSchoolId == externalSchoolId);

            if (!school) {
                v.isNewSchool = true;
                return;
            }

            v.isNewSchool = false;

            // apply user to local data
            v.school = {
                ...v.school,
                ...school,
            };

            const { schoolId, schoolTerms } = school;
            if (!schoolTerms || schoolTerms.length == 0) return;
            const schoolTermId = (schoolTerms.find((t) => t.current) || schoolTerms[0] ).schoolTermId;

            network.admin.getSchoolDataSystems({ url: { schoolId, schoolTermId }}, (err, res) => {
                if (err) return v.showError(err);
                const { dataSystems } = res;
                v.hasStarsClassroom = Boolean(dataSystems.find((ds) => ds.dataSystem === 'STARS Classroom'));
            });
        },
        getDistricts() {
            const { showError } = this;
            const v = this;
            v.loading = true;
            network.admin.listDistricts({}, (err, res) => {
                v.loading = false;
                if (err) return showError(err);
                v.districts = res.districts.filter((d) => !d.deleted);
            });
        },
        deleteSchool() {
            this.saveSchool(true);
        },
        restoreSchool() {
            this.saveSchool(false);
        },
        saveSchool(deleted = false) {
            const v = this;
            if (v.saving) return;
            v.saving = true;

            const { school } = v;

            const params = {
                url: {
                    externalSchoolId: school.externalSchoolId,
                },
                body: {
                    school: {
                        contactEmail: school.contactEmail,
                        schoolName: school.schoolName,
                        googleSetting: school.googleSetting,
                        requireTwoFactorAuth: school.requireTwoFactorAuth,
                        restricted: school.restricted,
                        schoolStatus: school.schoolStatus,
                        schoolRenewalStatus: school.schoolRenewalStatus,
                        schoolMessageEnabled: school.schoolMessageEnabled,
                        schoolMessage: school.schoolMessage,
                        schoolMessageScope: school.schoolMessageScope,
                        schoolMessageVariant: school.schoolMessageVariant,
                        districtId: school.district.districtId,
                        isBetaSchool: school.isBetaSchool,
                        hasStarsClassroom: v.hasStarsClassroom,
                        deleted,
                    },
                },
            };

            network.admin.editSchool(params, (err) => {
                v.saving = false;
                if (err) return v.showError(err);
                this.isEmailFound = true;
                v.$store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, {
                    tableName: 'schools',
                    callback: (err2) => {
                        if (err2) return v.showError(err2);
                        v.showAlert('School edited', null, 'success');
                        v.exit();
                    },
                });
            });
        },
        createSchool() {
            const v = this;
            if (v.saving) return;
            const { primaryContact, school, schoolTerm } = v;
            v.saving = true;

            let startDate;
            if (schoolTerm.term == '1') startDate = `${schoolTerm.year}-09-01`;
            if (schoolTerm.term == '2') startDate = `${schoolTerm.year + 1}-02-01`;
            if (schoolTerm.term == '7') startDate = `${schoolTerm.year + 1}-07-01`;

            if (startDate) {
                const termStart = moment(startDate, 'YYYY-MM-DD');
                const days = Math.round(moment.duration(moment().startOf('day') - termStart).asDays());
                const confirmationMessage = `You are about to create a school term
                    that ${days < 0 ? `begins in around ${Math.abs(days)} days` : `began about ${Math.abs(days)} days ago`} in
                    ${termStart.format('MMMM')} of ${termStart.format('YYYY')}.

                    Press ok to continue.`;
                if (!window.confirm(confirmationMessage)) {
                    v.saving = false;
                    return;
                }
            }

            const params = {
                body: {
                    primaryContact: {
                        schoolEmail: primaryContact.schoolEmail,
                        firstName: primaryContact.firstName,
                        lastName: primaryContact.lastName,
                    },
                    school: {
                        schoolName: school.schoolName,
                        externalSchoolId: school.externalSchoolId,
                        districtId: school.district.districtId,
                        googleSetting: school.googleSetting,
                    },
                    schoolTerm: {
                        schoolTerm: schoolTerm.term,
                        schoolYear: schoolTerm.year,
                    },
                },
            };

            const { showError, $router } = this;
            network.admin.createSchool(params, (err) => {
                v.saving = false;
                if (err) return showError(err);
                v.$store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, {
                    tableName: 'schools',
                    callback(err2) {
                        if (err2) return showError(err2);
                        $router.push({ name: 'SuperSchools' });
                    },
                });
            });
        },
    },
};
</script>

<style scoped>
h3.kt-portlet__head-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sg-bordered-row {
    border: solid lightgrey 1px;
    border-radius: 10px;
    padding: 10px;
}

.sg-bordered-row.caution {
    border: solid orange 1px;
}

.sg-bordered-row.success {
    border: solid green 1px;
}

span.sg-border-label {
    margin-top: -20px;
    padding-left: 6px;
    padding-right: 6px;
    background-color: white;
    font-weight: 500;
    font-size: 1.1em;
}

span.sg-border-label.caution {
    color: orange;
}

span.sg-border-label.success {
    color: green;
}

.is-invalid-warn {
    border-color: #ffb822 !important;
}
</style>
