<template>
<div v-if="school">
    <div class="kt-section mb-2">
        <div class="kt-section__title">
            School Terms
        </div>
    </div>

    <div class="kt-section mb-0">
        <div class="kt-widget2">
            <div
                v-for="term in schoolTerms"
                :key="'terms_' + term.schoolTermId"
                class="kt-widget2__item"
                :class="{
                    'kt-widget2__item--primary': term.current,
                    //'kt-widget2__item--danger': !term.finalized,
                    'kt-widget2__item--warning': term.finalized && !term.current,
                }"
            >
                <div class="kt-widget2__checkbox ml-1 p-0 pl-4 pb-2" />
                <div class="kt-widget2__info pt-2">
                    <a
                        href="#"
                        class="kt-widget2__title"
                        @click.stop.prevent="impersonateSchoolTerm(term, '/configuration/school/terms')"
                    >
                        {{ term.schoolYear }} - {{ term.schoolYearPlusOne }} School Year

                        <span class="ml-2 mr-2"> - </span>
                        <span>Term {{ term.schoolTerm }}</span>
                        <span v-if="term.current" class="kt-badge kt-badge--success kt-badge--inline ml-5">
                            DEFAULT
                        </span>

                    </a>

                    <span v-if="!term.finalized" class="text-muted kt-font-danger">
                        This term needs to be finalized before it can be used!
                    </span>

                    <span v-else class="text-muted">
                        {{ term.termRange }}
                    </span>
                </div>
                <div class="kt-widget2__actions">
                    <a
                        href="#"
                        class="btn btn-clean btn-sm btn-bold btn-icon"
                        @click.stop.prevent="impersonateSchoolTerm(term)"
                    >
                        <i class="fa fa-user-secret" />

                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script  lang="ts">

import { mapState } from 'vuex';
import moment from 'moment';
import Types from '../../store/Types';

export default {
    name: 'SchoolTerms',
    props: {},
    computed: {
        ...mapState({
            user: (state) => state.user,
            userName: (state) => state.user.mappedUserName || state.user.userName,
            schools: (state) => state.database.schools,
        }),
        school() {
            const { externalSchoolId } = this.$route.params;
            return this.schools.find((s) => s.externalSchoolId == externalSchoolId) || null;
        },
        schoolTerms() {
            if (!this.school) return [];
            const sortOn = (a, b) => ((`${a.schoolYear}_${a.schoolTerm}` > `${b.schoolYear}_${b.schoolTerm}`) ? 1 : -1);
            return this.school.schoolTerms.map((t) => {
                const term = t;
                const termStart = t.termStart.substring(0, 10);
                const termEnd = t.termEnd.substring(0, 10);
                const startDate = moment(termStart, 'YYYY-MM-DD');
                const endDate = moment(termEnd, 'YYYY-MM-DD');
                const formattedStart = startDate.format('ddd MMM Mo YYYY');
                const formattedEnd = endDate.format('ddd MMM Mo YYYY');
                term.termRange = `${formattedStart} - ${formattedEnd}`;
                term.termStart = termStart;
                term.termEnd = termEnd;
                term.schoolYearPlusOne = parseInt(t.schoolYear, 10) + 1;
                return term;
            }).sort(sortOn);
        },
        currentTerm() {
            return this.schoolTerms.find((t) => t.current) || null;
        },
    },
    methods: {
        impersonateSchool() {
            if (this.currentTerm) {
                this.impersonateSchoolTerm(this.currentTerm);
            }
        },
        impersonateSchoolTerm(term, href = '/home') {
            const userId = this.school.contactUserId;
            this.impersonate({
                userId,
                schoolTermId: term.schoolTermId,
                href,
            });
        },
        impersonate(user) {
            this.$store.dispatch(Types.actions.IMPERSONATE, user);
        },
    },
};
</script>
