var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.schools.length > 0 && !_vm.loading
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass:
                "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "kt-portlet kt-portlet--mobile kt-portlet",
                      },
                      [
                        _c("div", { staticClass: "kt-portlet__head" }, [
                          _c("div", { staticClass: "kt-portlet__head-label" }, [
                            _vm.isNewSchool
                              ? _c(
                                  "h3",
                                  { staticClass: "kt-portlet__head-title" },
                                  [_vm._v(" Add New School ")]
                                )
                              : _c(
                                  "h3",
                                  { staticClass: "kt-portlet__head-title" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.school.externalSchoolId) +
                                        " " +
                                        _vm._s(_vm.school.schoolName) +
                                        " "
                                    ),
                                  ]
                                ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "kt-portlet__head-toolbar" },
                            [
                              _c(
                                "div",
                                { staticClass: "kt-portlet__head-group" },
                                [
                                  !_vm.isNewSchool
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-label-primary btn-bold alignment pull-right",
                                          attrs: {
                                            type: "button",
                                            disabled: !_vm.isFormValid,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.impersonate()
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-user-secret",
                                          }),
                                          _vm._v(" Active Term "),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "kt-portlet__body" },
                          [
                            !_vm.isNewSchool ? _c("SchoolTerms") : _vm._e(),
                            !_vm.isNewSchool
                              ? _c("div", {
                                  staticClass:
                                    "kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit",
                                })
                              : _vm._e(),
                            _vm._m(0),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group validated row pl-2 pr-2",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "p-3 pr-4 col-xl-4 col-md-6" },
                                  [
                                    _vm._v(" District: "),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.school.district.districtId,
                                            expression:
                                              "school.district.districtId",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { disabled: !_vm.isNewSchool },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.school.district,
                                              "districtId",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(_vm.districts, function (d) {
                                        return _c(
                                          "option",
                                          {
                                            key: `district_${d.districtId}`,
                                            domProps: { value: d.districtId },
                                          },
                                          [_vm._v(" " + _vm._s(d.name) + " ")]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "p-3 pr-4 col-xl-4 col-md-6" },
                                  [
                                    _vm._v(" External School Id: "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.school.externalSchoolId,
                                          expression: "school.externalSchoolId",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.isExternalSchoolIdValid
                                        ? ""
                                        : "is-invalid",
                                      attrs: {
                                        disabled: !_vm.isNewSchool,
                                        type: "text",
                                      },
                                      domProps: {
                                        value: _vm.school.externalSchoolId,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.school,
                                            "externalSchoolId",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    !_vm.isExternalSchoolIdValid
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              " Please enter a six character external school id "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "p-3 pr-4 col-xl-4 col-md-6" },
                                  [
                                    _vm._v(" School Name: "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.school.schoolName,
                                          expression: "school.schoolName",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.isSchoolNameValid
                                        ? ""
                                        : "is-invalid",
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.school.schoolName,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.school,
                                            "schoolName",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    !_vm.isSchoolNameValid
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              " Please enter a school name "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "p-3 pr-4 col-xl-4 col-md-6" },
                                  [
                                    _vm._v(" Primary Contact Email Address: "),
                                    _vm.isNewSchool
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.primaryContact.schoolEmail,
                                              expression:
                                                "primaryContact.schoolEmail",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: _vm.isValidSchoolDomain
                                            ? ""
                                            : "is-invalid",
                                          attrs: {
                                            placeholder: _vm.mySchoolDomain
                                              ? `e.g. barack.obama@${_vm.mySchoolDomain}`
                                              : "",
                                            type: "email",
                                          },
                                          domProps: {
                                            value:
                                              _vm.primaryContact.schoolEmail,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.primaryContact,
                                                "schoolEmail",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        })
                                      : _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.school.contactEmail,
                                              expression: "school.contactEmail",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class:
                                            _vm.isValidSchoolDomain &&
                                            _vm.isEmailFound
                                              ? ""
                                              : "is-invalid",
                                          attrs: {
                                            placeholder: _vm.mySchoolDomain
                                              ? `e.g. barack.obama@${_vm.mySchoolDomain}`
                                              : "",
                                            type: "email",
                                          },
                                          domProps: {
                                            value: _vm.school.contactEmail,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.school,
                                                "contactEmail",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                    !_vm.isValidSchoolDomain
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "invalid-danger-feedback",
                                          },
                                          [
                                            _vm._v(
                                              " Please enter a valid email address "
                                            ),
                                            _vm.mySchoolDomain
                                              ? [
                                                  _vm._v(" ending in "),
                                                  _c("code", [
                                                    _vm._v(
                                                      "@" +
                                                        _vm._s(
                                                          _vm.mySchoolDomain
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "p-3 pr-4 col-xl-4 col-md-6" },
                                  [
                                    _vm._v(" Google Integration Type "),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.school.googleSetting,
                                            expression: "school.googleSetting",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.school,
                                              "googleSetting",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(_vm.googleSettings, function (s) {
                                        return _c(
                                          "option",
                                          {
                                            key: `googleSettings${s}`,
                                            domProps: { value: s },
                                          },
                                          [_vm._v(" " + _vm._s(s) + " ")]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p-3 pr-4 col-xl-4 col-md-6 row",
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "label",
                                        { staticClass: "w-100 text-no-wrap" },
                                        [_vm._v("Require 2FA")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "kt-switch kt-switch--sm kt-switch--icon",
                                        },
                                        [
                                          _c("label", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.school
                                                      .requireTwoFactorAuth,
                                                  expression:
                                                    "school.requireTwoFactorAuth",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.school
                                                    .requireTwoFactorAuth
                                                )
                                                  ? _vm._i(
                                                      _vm.school
                                                        .requireTwoFactorAuth,
                                                      null
                                                    ) > -1
                                                  : _vm.school
                                                      .requireTwoFactorAuth,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.school
                                                        .requireTwoFactorAuth,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.school,
                                                          "requireTwoFactorAuth",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.school,
                                                          "requireTwoFactorAuth",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.school,
                                                      "requireTwoFactorAuth",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _c("span"),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    !_vm.isNewSchool
                                      ? _c("div", { staticClass: "row" }, [
                                          _c("div", [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "w-100 text-no-wrap",
                                              },
                                              [_vm._v("K-5 School")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "kt-switch kt-switch--outline kt-switch--icon kt-switch--success",
                                              },
                                              [
                                                _c("label", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.hasStarsClassroom,
                                                        expression:
                                                          "hasStarsClassroom",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      disabled: _vm.saving,
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.hasStarsClassroom
                                                      )
                                                        ? _vm._i(
                                                            _vm.hasStarsClassroom,
                                                            null
                                                          ) > -1
                                                        : _vm.hasStarsClassroom,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.hasStarsClassroom,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.hasStarsClassroom =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.hasStarsClassroom =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.hasStarsClassroom =
                                                            $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c("span"),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                          _c("div", [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "w-100 text-no-wrap",
                                              },
                                              [_vm._v("Beta School")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "kt-switch kt-switch--outline kt-switch--icon kt-switch--warning",
                                              },
                                              [
                                                _c("label", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.school
                                                            .isBetaSchool,
                                                        expression:
                                                          "school.isBetaSchool",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.school.isBetaSchool
                                                      )
                                                        ? _vm._i(
                                                            _vm.school
                                                              .isBetaSchool,
                                                            null
                                                          ) > -1
                                                        : _vm.school
                                                            .isBetaSchool,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.school
                                                              .isBetaSchool,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm.school,
                                                                "isBetaSchool",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm.school,
                                                                "isBetaSchool",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm.school,
                                                            "isBetaSchool",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c("span"),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm.isNewSchool
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "p-3 pr-4 col-xl-4 col-md-6",
                                        },
                                        [
                                          _vm._v(
                                            " Primary Contact First Name: "
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.primaryContact.firstName,
                                                expression:
                                                  "primaryContact.firstName",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class:
                                              _vm.isPrimaryContactFirstNameValid
                                                ? ""
                                                : "is-invalid",
                                            attrs: {
                                              type: "text",
                                              disabled: !_vm.isNewSchool,
                                            },
                                            domProps: {
                                              value:
                                                _vm.primaryContact.firstName,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.primaryContact,
                                                  "firstName",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          !_vm.isPrimaryContactFirstNameValid
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback",
                                                },
                                                [
                                                  _vm._v(
                                                    " Please enter a valid first name. "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "p-3 pr-4 col-xl-4 col-md-6",
                                        },
                                        [
                                          _vm._v(
                                            " Primary Contact Last Name: "
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.primaryContact.lastName,
                                                expression:
                                                  "primaryContact.lastName",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class:
                                              _vm.isPrimaryContactLastNameValid
                                                ? ""
                                                : "is-invalid",
                                            attrs: {
                                              type: "text",
                                              disabled: !_vm.isNewSchool,
                                            },
                                            domProps: {
                                              value:
                                                _vm.primaryContact.lastName,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.primaryContact,
                                                  "lastName",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          !_vm.isPrimaryContactLastNameValid
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback",
                                                },
                                                [
                                                  _vm._v(
                                                    " Please enter a valid last name. "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "p-3 pr-4 col-xl-4 col-md-6",
                                        },
                                        [
                                          _vm._v(" Initial School Year "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.schoolTerm.year,
                                                expression: "schoolTerm.year",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: _vm.isSchoolYearValid
                                              ? ""
                                              : "is-invalid",
                                            attrs: { type: "number" },
                                            domProps: {
                                              value: _vm.schoolTerm.year,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.schoolTerm,
                                                  "year",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          !_vm.isSchoolYearValid
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback",
                                                },
                                                [
                                                  _vm._v(
                                                    " Please enter a valid school year "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "p-3 pr-4 col-xl-4 col-md-6",
                                        },
                                        [
                                          _vm._v(" Initial School Term "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.schoolTerm.term,
                                                expression: "schoolTerm.term",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: _vm.isSchoolTermValid
                                              ? ""
                                              : "is-invalid",
                                            attrs: { type: "number" },
                                            domProps: {
                                              value: _vm.schoolTerm.term,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.schoolTerm,
                                                  "term",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          !_vm.isSchoolTermValid
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback",
                                                },
                                                [
                                                  _vm._v(
                                                    " Please enter a valid school term: " +
                                                      _vm._s(
                                                        [
                                                          "1",
                                                          "2",
                                                          "3",
                                                          "4",
                                                          "7",
                                                        ].join(", ")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c("div", { staticClass: "p-4 mb-2" }, [
                              _c(
                                "div",
                                { staticClass: "kt-form__actions" },
                                [
                                  !_vm.isNewSchool
                                    ? [
                                        !_vm.school.deleted
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-label-danger btn-bold",
                                                attrs: {
                                                  type: "button",
                                                  "data-dismiss": "modal",
                                                  disabled: !_vm.isFormValid,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.deleteSchool()
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-trash",
                                                }),
                                                _vm._v(" Delete School "),
                                              ]
                                            )
                                          : _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-label-warning btn-bold",
                                                attrs: {
                                                  type: "button",
                                                  "data-dismiss": "modal",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.restoreSchool()
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-save",
                                                }),
                                                _vm._v(" Restore School "),
                                              ]
                                            ),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-danger btn-bold ml-4",
                                            attrs: {
                                              type: "button",
                                              "data-dismiss": "modal",
                                              disabled: _vm.permDeleteDisabled,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.confirmDeletePermanently()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-ban",
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.permDeleteDisabled
                                                    ? "Delete After Peak Hours"
                                                    : "Delete Permanently"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-bold ml-3 pull-right",
                                      class: {
                                        "kt-spinner kt-spinner--sm kt-spinner--light":
                                          _vm.saving,
                                        "btn btn-label-primary alignment pull-right":
                                          _vm.isNewSchool && !_vm.saving,
                                        "btn btn-label-success mr-2":
                                          !_vm.isNewSchool && !_vm.saving,
                                      },
                                      attrs: {
                                        type: "button",
                                        disabled: !_vm.isFormValid,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          _vm.isNewSchool
                                            ? _vm.createSchool()
                                            : _vm.saveSchool()
                                        },
                                      },
                                    },
                                    [
                                      !_vm.saving
                                        ? _c("i", { staticClass: "fa fa-save" })
                                        : _vm._e(),
                                      _vm.saving
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.deleteConfirm ==
                                                    _vm.deleteConfirmText
                                                    ? "Deleting..."
                                                    : "Saving..."
                                                ) +
                                                " "
                                            ),
                                          ]
                                        : [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.isNewSchool
                                                    ? "Create School"
                                                    : "Save Changes"
                                                ) +
                                                " "
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                ],
                                2
                              ),
                            ]),
                            !_vm.isNewSchool
                              ? _c("div", {
                                  staticClass:
                                    "kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit",
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    !_vm.isNewSchool
                      ? _c("SchoolSubscriptions", {
                          attrs: {
                            "external-school-id": _vm.school
                              ? _vm.school.externalSchoolId
                              : null,
                            "school-year": _vm.thisYear,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c("b-modal", {
            ref: "perm-delete-modal",
            attrs: { size: "xl", centered: "" },
            on: {
              hide: function ($event) {
                _vm.deleteConfirm = ""
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "modal-header",
                  fn: function ({ close }) {
                    return [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(" Are you sure? "),
                      ]),
                      _c("button", {
                        staticClass: "close",
                        attrs: {
                          type: "button",
                          "data-dismiss": "modal",
                          "aria-label": "Close",
                          disabled:
                            _vm.saving &&
                            _vm.deleteConfirm == _vm.deleteConfirmText,
                        },
                        on: {
                          click: function ($event) {
                            return close()
                          },
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "kt-portlet__body p-4" }, [
                        _c(
                          "div",
                          { staticClass: "tab-content" },
                          [
                            [
                              _c(
                                "div",
                                { staticClass: "kt-section kt-section--first" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "kt-section__body" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-lg-3 col-form-label",
                                            },
                                            [_vm._v("Confirm School Delete:")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-6" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.deleteConfirm,
                                                    expression: "deleteConfirm",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: _vm.deleteConfirm,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.deleteConfirm =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "form-text text-muted kt-font-danger",
                                                },
                                                [
                                                  _vm._v(
                                                    " Please enter the text "
                                                  ),
                                                  _c("code", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.deleteConfirmText
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " to acknowledge that this delete is permanant and to proceed "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                          ],
                          2
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "modal-footer",
                  fn: function ({ close }) {
                    return [
                      _vm.saving
                        ? _c(
                            "div",
                            { staticClass: "pr-4 font-weight-normal" },
                            [_vm._v(" This may take a few minutes... ")]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary mr-3",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return close()
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                      !_vm.isProd
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-danger btn-bold pull-right",
                              class: {
                                "kt-spinner kt-spinner--sm kt-spinner--light":
                                  _vm.saving,
                              },
                              attrs: {
                                disabled:
                                  _vm.saving ||
                                  _vm.deleteConfirm != _vm.deleteConfirmText,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.deleteSchoolPermanently.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              !_vm.saving
                                ? _c("i", { staticClass: "fa fa-ban" })
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.saving
                                      ? "Deleting..."
                                      : "Delete Permanently"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              1721466250
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-section mb-0 mt-3" }, [
      _c("div", { staticClass: "kt-section__title" }, [
        _vm._v(" School Details "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }