var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.school
    ? _c("div", [
        _vm._m(0),
        _c("div", { staticClass: "kt-section mb-0" }, [
          _c(
            "div",
            { staticClass: "kt-widget2" },
            _vm._l(_vm.schoolTerms, function (term) {
              return _c(
                "div",
                {
                  key: "terms_" + term.schoolTermId,
                  staticClass: "kt-widget2__item",
                  class: {
                    "kt-widget2__item--primary": term.current,
                    //'kt-widget2__item--danger': !term.finalized,
                    "kt-widget2__item--warning":
                      term.finalized && !term.current,
                  },
                },
                [
                  _c("div", {
                    staticClass: "kt-widget2__checkbox ml-1 p-0 pl-4 pb-2",
                  }),
                  _c("div", { staticClass: "kt-widget2__info pt-2" }, [
                    _c(
                      "a",
                      {
                        staticClass: "kt-widget2__title",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.impersonateSchoolTerm(
                              term,
                              "/configuration/school/terms"
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(term.schoolYear) +
                            " - " +
                            _vm._s(term.schoolYearPlusOne) +
                            " School Year "
                        ),
                        _c("span", { staticClass: "ml-2 mr-2" }, [
                          _vm._v(" - "),
                        ]),
                        _c("span", [_vm._v("Term " + _vm._s(term.schoolTerm))]),
                        term.current
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "kt-badge kt-badge--success kt-badge--inline ml-5",
                              },
                              [_vm._v(" DEFAULT ")]
                            )
                          : _vm._e(),
                      ]
                    ),
                    !term.finalized
                      ? _c(
                          "span",
                          { staticClass: "text-muted kt-font-danger" },
                          [
                            _vm._v(
                              " This term needs to be finalized before it can be used! "
                            ),
                          ]
                        )
                      : _c("span", { staticClass: "text-muted" }, [
                          _vm._v(" " + _vm._s(term.termRange) + " "),
                        ]),
                  ]),
                  _c("div", { staticClass: "kt-widget2__actions" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-clean btn-sm btn-bold btn-icon",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.impersonateSchoolTerm(term)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-user-secret" })]
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-section mb-2" }, [
      _c("div", { staticClass: "kt-section__title" }, [
        _vm._v(" School Terms "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }